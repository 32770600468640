<template>
	<div class="alignment">
		<ZyroIconControls
			:current.sync="propertyValue"
			:icons="icons"
			:toggleable="false"
		/>
	</div>
</template>

<script>
import {
	mapGetters,
	mapMutations,
} from 'vuex';

import {
	alignIcons,
	justifyIcons,
} from '@/components/builder-controls/reusable-controls/alignIcons';

export const ALIGN_PROPERTY = 'align';
export const JUSTIFY_PROPERTY = 'justify';

const propertiesMap = {
	[ALIGN_PROPERTY]: alignIcons,
	[JUSTIFY_PROPERTY]: justifyIcons,
};

export default {
	props: {
		styleProperty: {
			type: String,
			validator: (value) => [
				ALIGN_PROPERTY,
				JUSTIFY_PROPERTY,
			].includes(value),
			required: true,
		},
	},
	computed: {
		...mapGetters(['currentBlock']),
		icons() {
			return propertiesMap[this.styleProperty];
		},
		propertyValue: {
			get() {
				return this.currentBlock.settings.styles[this.styleProperty];
			},
			set(newValue) {
				this.setBlockData({ data: { settings: { styles: { [this.styleProperty]: newValue } } } });
			},
		},
	},
	methods: mapMutations(['setBlockData']),
};
</script>

<style lang="scss" scoped>
.alignment {
	display: flex;

	> *:not(:last-child) {
		margin-right: 24px;
	}
}
</style>
