<template>
	<ZyroCssShorthandControl
		:show-horizontal="isMobileScreen || isMobileView"
		:value.sync="padding"
		units="px"
		:label="$t('builder.editBlockTabLayout.controlRangeLabel')"
		bold
	/>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
	mapActions,
} from 'vuex';

export default {
	computed: {
		...mapGetters(['currentBlock']),
		...mapState(['currentBlockId']),
		...mapState('gui', [
			'isMobileView',
			'isMobileScreen',
		]),
		padding: {
			set(newValue) {
				const key = this.isMobileView || this.isMobileScreen ? 'm-block-padding' : 'block-padding';

				this.setEditMode(false);
				this.setBlockData({
					blockId: this.currentBlockId,
					data: { settings: { styles: { [key]: newValue } } },
				});
			},
			get() {
				const key = this.isMobileView || this.isMobileScreen ? 'm-block-padding' : 'block-padding';

				return this.currentBlock.settings.styles[key] || '0';
			},
		},
	},
	methods: {
		...mapMutations(['setBlockData']),
		...mapActions('gui', ['setEditMode']),
	},
};
</script>
