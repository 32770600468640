<template>
	<EditBlockVisibleElements :value-map="$options.keyToTextValueMap" />
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
} from 'vuex';

import EditBlockVisibleElements from '@/components/builder-controls/reusable-controls/block/EditBlockVisibleElements.vue';
import i18n from '@/i18n/setup';
import { cloneDeep } from '@/utils/object';

const keyToTextValueMap = {
	description: i18n.t('builder.blog.blockBlogList.post.description'),
	categories: i18n.t('builder.blog.blockBlogList.post.categories'),
	authorFullName: i18n.t('builder.blog.blockBlogList.post.authorFullName'),
	date: i18n.t('builder.blog.blockBlogList.post.date'),
	/*
	 * Remove avatar until we can change avatar image
	 * avatar: i18n.t('builder.blog.blockBlogList.post.avatar'),
	 */
	minutesToRead: i18n.t('builder.blog.blockBlogList.post.minutesToRead'),
};

export default {
	components: { EditBlockVisibleElements },
	data() {
		return {
			id: null,
			currentBlockBeforeEdit: null,
		};
	},
	computed: {
		...mapState(['currentBlockId']),
		...mapGetters(['currentBlock']),
	},
	mounted() {
		this.id = this.currentBlockId;
		this.currentBlockBeforeEdit = cloneDeep(this.currentBlock);
	},
	beforeDestroy() {
		this.pushBlockDataToHistory(
			{
				elementId: this.id,
				oldData: this.currentBlockBeforeEdit,
			},
		);
	},
	methods: mapMutations([
		'pushBlockDataToHistory',
		'setBlockData',
	]),
	keyToTextValueMap,
};
</script>
