import { render, staticRenderFns } from "./EditBlockPadding.vue?vue&type=template&id=6685c6cf&"
import script from "./EditBlockPadding.vue?vue&type=script&lang=js&"
export * from "./EditBlockPadding.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports