<template>
	<div class="layout">
		<ZyroLabel class="layout__label">
			{{ $t('builder.editButton.tabLayoutLabel') }}
		</ZyroLabel>
		<AlignmentControls :style-property="$options.ALIGN_PROPERTY" />
		<EditBlockPadding />
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
} from 'vuex';

import AlignmentControls, { ALIGN_PROPERTY } from '@/components/builder-controls/reusable-controls/block/AlignmentControls.vue';
import EditBlockPadding from '@/components/builder-controls/reusable-controls/block/EditBlockPadding.vue';
import { cloneDeep } from '@/utils/object';

export default {
	ALIGN_PROPERTY,
	components: {
		AlignmentControls,
		EditBlockPadding,
	},
	data() {
		return {
			id: null,
			currentBlockBeforeEdit: null,
		};
	},
	computed: {
		...mapState(['currentBlockId']),
		...mapGetters(['currentBlock']),
	},
	mounted() {
		this.id = this.currentBlockId;
		this.currentBlockBeforeEdit = cloneDeep(this.currentBlock);
	},
	beforeDestroy() {
		this.pushBlockDataToHistory(
			{
				elementId: this.id,
				oldData: this.currentBlockBeforeEdit,
			},
		);
	},
	methods: mapMutations(['pushBlockDataToHistory']),
};
</script>

<style lang="scss" scoped>
.layout {
	&__label {
		margin-top: 16px;
	}
}
</style>
