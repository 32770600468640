<template>
	<ZyroPopupCard
		type="editor"
		:title="$t('builder.blog.blogPostHeader.postHeader')"
		:tabs="$options.tabs"
		:current-tab="currentTab"
		@change="currentTab = $event"
		@close="$emit('close')"
	>
		<Component
			:is="currentTab.component"
			@close="$emit('close')"
		/>
	</ZyroPopupCard>
</template>

<script>
import EditBlockTabBackground from '@/components/builder-controls/edit-block/EditBlockTabBackground.vue';
import i18n from '@/i18n/setup';

import EditBlockBlogHeaderTabLayout from './EditBlockBlogHeaderTabLayout.vue';
import EditBlockBlogHeaderTabStyle from './EditBlockBlogHeaderTabStyle.vue';

const tabs = [
	{
		id: 'style',
		title: i18n.t('common.style'),
		component: 'EditBlockBlogHeaderTabStyle',
	},
	{
		id: 'layout',
		title: i18n.t('common.layout'),
		component: 'EditBlockBlogHeaderTabLayout',
	},
	{
		id: 'background',
		title: i18n.t('common.background'),
		component: 'EditBlockTabBackground',
	},
];

export default {
	components: {
		EditBlockBlogHeaderTabStyle,
		EditBlockBlogHeaderTabLayout,
		EditBlockTabBackground,
	},
	data() {
		return { currentTab: this.$options.tabs[0] };
	},
	created() {
		this.currentTab = this.$options.tabs
			.find((tab) => tab.id === this.startTabId)
			|| this.$options.tabs[0];
	},
	tabs,
};
</script>
