export const alignIcons = [
	{
		value: 'flex-start',
		icon: 'align-left',
	},
	{
		value: 'center',
		icon: 'align-center',
	},
	{
		value: 'flex-end',
		icon: 'align-right',
	},
];

export const justifyIcons = [
	{
		value: 'flex-start',
		icon: 'align-top',
	},
	{
		value: 'center',
		icon: 'align-middle',
	},
	{
		value: 'flex-end',
		icon: 'align-bottom',
	},
];
